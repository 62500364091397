import { useState, useEffect, useMemo } from 'react';

const StripeAccountLink = () => {
  const [accountLinkUrl, setAccountLinkUrl] = useState('');
  const walletAddress = localStorage.getItem('walletAddress');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  useEffect(() => {
    const fetchStripeConnectIdAndGenerateLink = async () => {
      try {
        // Assuming the existence of an endpoint that can return `stripe_connect_id` for a given `walletAddress`
        const metadataURL = `${linkUrl}/wp-json/custom/v1/get-metadata-by-metadata/?metadata_name_A=wallet_address&value_metadata_name_A=${walletAddress}&metadata_name_B=stripe_connect_id`;
        const response = await fetch(metadataURL);  
        const metadata = await response.json();
        const stripeConnectId = metadata.meta_value_B;


        if (stripeConnectId) {
          const linkResponse = await fetch(`${linkUrl}/wp-json/custom/v1/generate-account-link/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              stripe_connect_id: stripeConnectId,
            }),
          });

          const linkData = await linkResponse.json();
          setAccountLinkUrl(linkData.url); // Assuming the endpoint returns { url: '...' }
        }
      } catch (error) {
        console.error('Error generating Stripe account link:', error);
      }
    };

    fetchStripeConnectIdAndGenerateLink();
  }, [walletAddress]);

  return (
    <div>
      {accountLinkUrl ? (
        <a href={accountLinkUrl} target="_blank" rel="noopener noreferrer">
          Complete Stripe Onboarding
        </a>
      ) : (
        'Activate the Cash Wallet'
      )}
    </div>
  );
};

export default StripeAccountLink;
